import React, { useState } from "react"
import newsImage from "../images/newsletter.png"
import Button from "./Button"

const NewsLetter = () => {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [isLoading, setLoader] = useState(false)

  function ValidateEmail(inputText) {
    var mailformat = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/
    if (inputText.match(mailformat)) {
      return true
    } else {
      return false
    }
  }

  const nameHandler = e => {
    e.preventDefault()
    setName(e.target.value)
  }
  const emailHandler = e => {
    e.preventDefault()
    setEmail(e.target.value)
  }

  var handleclick = () => {
    console.log(email)
    console.log(ValidateEmail(email))
    if (ValidateEmail(email) === true) {
      setLoader(true)
      console.log("valid Email enterd")
      var formdata = new FormData()
      formdata.append("api_key", "kzoASSeRGxBe3ctHAg9DkQ")
      formdata.append("email", email)
      formdata.append("name", name)

      var requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      }

      fetch(
        "https://api.convertkit.com/v3/forms/2223068/subscribe",
        requestOptions
      )
        .then(response => response.text())
        .then(result => {
          alert("Check your Email for confirmation")
          console.log(result)
          setLoader(false)
        })
        .catch(error => {
          alert("Something Went Wrong Try Again")
          console.log("error", error)
          setLoader(true)
        })
    } else {
      alert("Enter Valid Email")
    }
  }

  return (
    <div className="max-w-screen-xl grid grid-cols-4 gap-6 lg:grid-cols-12 mx-4 md:mx-12 lg:mx-32 items-center mb-8 md:mb-16 mt-4 md:mt-8" id="earlyaccess">
      <div className="col-span-4 row-start-2  lg:row-start-1 lg:col-span-6">
        <div className="mobH2 md:deskH2 textGradient  text-center lg:text-left">
          Get early access & exclusive benefits!
        </div>
        <div className="mobP md:deskP textBlack text-center lg:text-left mt-3 lg:mt-6">
          Join the Flexeet Mailing Club where we send you exclusive unlisted
          templates, offers, tips and many other perks that puts you ahead of
          others in flexing your brand.
        </div>
        <div className="py-4 px-8 my-4 md:my-4 gradientBorder border rounded-full  mt-6 lg:mt-12">
          <input
            type="text"
            name="name"
            value={name}
            onChange={nameHandler}
            className="bg-transparent w-full focus:outline-none mobCTA-SMALL md:deskCTA-SMALL transform-none"
            placeholder="Enter your First Name"
            id="newsname"
            autoCapitalize="none"
          />
        </div>
        <div className="py-4 px-8 my-4 md:my-4 gradientBorder border rounded-full  mt-3 lg:mt-4">
          <input
            type="email"
            name="email"
            value={email}
            onChange={emailHandler}
            className="bg-transparent w-full focus:outline-none mobCTA-SMALL md:deskCTA-SMALL transform-none"
            placeholder="Enter your email address"
            id="newsemail"
            autoCapitalize="none"
          />
        </div>
        <Button
          title="Subscribe"
          buttonStyle="btnPrimary"
          buttonSize="btnBig"
          fitType="btnFull"
          isDisabled={isLoading}
          isLoading={isLoading}
          onBtnClick={handleclick}
        ></Button>
      </div>
      <div className="col-span-4 row-start-1  lg:row-start-1  lg:col-start-8 lg:col-span-5 my-4 sm:mt-16 md:m-0 h-full">
        <img src={newsImage} alt="newsletter" className="m-0" />
      </div>
    </div>
  )
}

export default NewsLetter
