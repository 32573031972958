import React from "react"
import heroImage from "../images/Hero-Image.png"
import "../styles/global.css"
import Button from "./Button"
const Hero = () => {
  return (
    <div className="max-w-screen-xl grid grid-cols-4 gap-6 lg:grid-cols-12 mx-4 md:mx-12 lg:mx-32 items-center my-8 md:my-12">
      <div className="grid w-full col-span-4 lg:col-span-6 gap-y-4 md:gap-y-8">
        <div className=" mobH1 md:deskH1 textGradient  text-center lg:text-left">
          Flex your tweets with Flexeet
        </div>
        <div className="mobP md:deskP textBlack text-center lg:text-left">
          Screenshots are boring. Turn your tweets into stunning images for
          sharing in seconds. Select a template, paste your tweet link and
          download.
        </div>
        <div className="grid lg:place-content-start place-content-center w-full mt-6 md:mt-4">
          <a href="/#templates">
            <Button
              title="Start Creating Now"
              buttonStyle="btnPrimary"
              buttonSize="btnBig"
              isDisabled={false}
            />
          </a>
        </div>
      </div>
      <div className="col-span-4 lg:col-span-6 my-4 sm:mt-16 md:m-8 h-full">
        <img src={heroImage} alt="hero" className="m-0" />
      </div>
    </div>
  )
}

export default Hero
