import React from "react"

const Feature = ({ image, title, subtitle }) => {
  return (
    <div className="flex flex-col content-center justify-center">
      <div className="col-span-1 self-center">
        <img src={image} alt="feature image" width="156px" className="mb-8" />
      </div>

      <div className="mobPB md:deskPB col-span-4 text-center">{title}</div>
      <div className="mobCTA-SMALL md:deskCTA-SMALL col-span-4 text-center">
        {subtitle}
      </div>
    </div>
  )
}

export default Feature
