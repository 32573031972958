import * as React from "react"
import "../styles/global.css"
import Header from "../components/header"
import Hero from "../components/hero"
import Menuoverlay from "../components/menuoverlay"
import CardSection from "../components/CardSection"
import FeaturesSection from "../components/FeaturesSection"
import NewsLetter from "../components/newsLetter"
import SupportSection from "../components/supportSection"
import ClosingSection from "../components/closingSection"
import { Footer } from "../components/Footer"
import Helmet from "react-helmet"
import ReactGA from "react-ga"
const IndexPage = () => {
  React.useEffect(() => {
    ReactGA.initialize("UA-192091890-1")
    ReactGA.pageview(window.location.pathname + window.location.search)
  }, []);

  return (
    <>
      <Helmet>
        {/* <!-- Primary Meta Tags --> */}
        <title>Flexeet.Club — Design Tweet Screenshots</title>
        <meta name="title" content="Flexeet.Club  — Design Tweet Screenshots" />
        <meta
          name="description"
          content="FlexeetClub is a web app that lets you turn your tweets into stunning images for sharing in seconds.Just select a template, paste your tweet link, and download."
        />

        {/* <!-- Open Graph / Facebook --> */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://flexeet.club/" />
        <meta
          property="og:title"
          content="Flexeet.Club  — Design Tweet Screenshots"
        />
        <meta
          property="og:description"
          content="FlexeetClub is a web app that lets you turn your tweets into stunning images for sharing in seconds. Just select a template, paste your tweet link, and download."
        />
        <meta
          property="og:image"
          content="https://i.ibb.co/7J9V1r9/Open-Graph.png"
        />

        {/* <!-- Twitter --> */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://flexeet.club/" />
        <meta
          property="twitter:title"
          content="Flexeet.Club  — Design Tweet Screenshots"
        />
        <meta
          property="twitter:description"
          content="FlexeetClub is a web app that lets you turn your tweets into stunning images for sharing in seconds. Just select a template, paste your tweet link, and download."
        />
        <meta
          property="twitter:image"
          content="https://i.ibb.co/7J9V1r9/Open-Graph.png"
        />
      </Helmet>
      <div className="grid content-start place-content-center">
        <div className="bodybg min-h-screen"></div>
        <Header />
        <Hero />
        <CardSection />
        <FeaturesSection />
        <NewsLetter />
        <SupportSection />
        <ClosingSection />
        <Footer />
      </div>
      <Menuoverlay />
    </>
  )
}

export default IndexPage
