import React from "react"
import Feature1 from "../images/Features01.png"
import Feature2 from "../images/Features02.png"
import Feature3 from "../images/Features03.png"
import Feature from "./Feature"

const FeaturesSection = () => {
  return (
    <div className="max-w-screen-xl gap-y-16 grid grid-cols-4 md:grid-cols-9 lg:grid-cols-12 mx-4 md:mx-12 lg:mx-32 items-center mt-20 lg:mt-32 mb-32">
      <div className="col-span-4 md:col-span-3 lg:col-span-4">
        <Feature
          image={Feature1}
          title="Light and Dark Mode"
          subtitle="Available for almost all templates"
        />
      </div>
      <div className="col-span-4 md:col-span-3 lg:col-span-4">
        <Feature
          image={Feature2}
          title="Post and Story Sizes"
          subtitle="To share across almost all platforms"
        />
      </div>
      <div className="col-span-4 md:col-span-3 lg:col-span-4">
        <Feature
          image={Feature3}
          title="Beautiful Templates"
          subtitle="Flex creatively every time!"
        />
      </div>
    </div>
  )
}

export default FeaturesSection
