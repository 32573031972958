import React from "react"
import LinkIconBlack from "../images/Icons/LinkBlack.svg"
import Button from "./Button"

const Card = ({ name, imgURL, url }) => {
  let baseURL = "https://app.flexeet.club/"

  function copyLink() {
    var dummy = document.createElement("input"),
      text = baseURL + "templates/" + name
    document.body.appendChild(dummy)
    dummy.value = text
    dummy.select()
    document.execCommand("copy")
    document.body.removeChild(dummy)
    alert("Template Link Copied")
  }

  return (
    <div className="col-span-4  rounded-lg cardBorder overflow-hidden hover:shadow-2xl">
      <img src={imgURL} alt="template image" className="m-0" />

      <div className="m-4">
        <div className="flex flex-row justify-between">
          <div className="textBlack mobPB md:deskPB capitalize">{name}</div>
          <img
            src={LinkIconBlack}
            alt="link"
            className="m-0 object-fill"
            onClick={copyLink}
          />
        </div>
        <div className="mt-4 w-full flex-1">
          <a href={baseURL + url}>
            <Button
              title="Use This template"
              buttonStyle="btnPrimary"
              buttonSize="btnSmall"
              isDisabled={false}
              fitType="btnFull"
            ></Button>
          </a>
        </div>
      </div>
    </div>
  )
}

export default Card
