import React from "react"
import Card from "./Card"
import Button from "./Button"

const CardSection = () => {
  let URLS = {
    colorflex: "https://i.ibb.co/cXQDkY5/Color-Flex.png",
    transparentflex: "https://i.ibb.co/sbz8nqg/Transparent-Flex.png",
    imageflex: "https://i.ibb.co/BBBvgDz/Image-Flex.png",
    gradientflex: "https://i.ibb.co/Qr6qVZr/Gradient-Flex.png",
  }

  let templates = [
    {
      name: "imageflex",
      url: "templates/imageflex",
    },
    {
      name: "gradientflex",
      url: "templates/gradientflex",
    },
    {
      name: "colorflex",
      url: "templates/colorflex",
    },
  ]

  return (
    <>
      <div
        className="textGradient mobH4 lg:deskH4 mx-4 md:mx-12 lg:mx-32  mt-12 md:mt-20 lg:mt-24 gap-y-8  text-center lg:text-left"
        id="templates"
      >
        Start by selecting a template
      </div>
      <div className="max-w-screen-xl grid grid-cols-4 md:grid-cols-8 gap-6 lg:grid-cols-12 mx-4 md:mx-12 lg:mx-32 items-center my-8 md:my-12">
        {templates.map(data => (
          <Card
            name={data.name}
            url={data.url}
            imgURL={URLS[data.name]}
            key={data.name}
          />
        ))}
      </div>
      <div className="grid place-content-center w-full mt-6 md:mt-4">
        <a href="https://app.flexeet.club">
          <Button
            title="More Templates"
            buttonStyle="btnPrimary"
            buttonSize="btnSmall"
            isDisabled={false}
          ></Button>
        </a>
      </div>
    </>
  )
}

export default CardSection
