import React from "react"
import supportImage from "../images/support.png"
import "../styles/global.css"
import Button from "./Button"
const SupportSection = () => {
  return (
    <div className="max-w-screen-xl grid grid-cols-4 lg:grid-cols-12 mx-4 md:mx-12 lg:mx-32 my-8 md:my-12">
      <div className="col-span-4 lg:col-span-6 my-4 sm:mt-16 md:m-8 h-full">
        <img src={supportImage} alt="support" className="m-0" />
      </div>
      <div className="grid w-full col-span-4 lg:col-span-6">
        <div className="mobH2 md:deskH2 textGradient  text-center lg:text-right">
          Become an early supporter!
        </div>
        <div className="mobP md:deskP textBlack text-center lg:text-right mt-3 lg:mt-6 xl:mt-6">
          If you enjoyed using Flexeet, we would truly appreciate your support.
          Become an early supporter, and we promise to keep working hard on new
          features and updates.
        </div>
        <div className="grid lg:place-content-end place-content-center w-full mt-6 lg:mt-12 xl:lg:mt-12">
          <a href="https://pages.razorpay.com/flexeetclub" target="_blank">
            <Button
              title="🦄 Support Us!"
              buttonStyle="btnPrimary"
              buttonSize="btnBig"
              isDisabled={false}
            />
          </a>
        </div>
      </div>
    </div>
  )
}

export default SupportSection
