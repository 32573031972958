import React from "react"
import Button from "./Button"

const ClosingSection = () => {
  return (
    <div className="flex flex-col justify-center justify-items-center bgCyan25">
      <div className="mobH1 md:deskH1 textBlack self-center mt-16 lg:mt-20">
        Start <span className="textGradient m-0">Flexing </span>Now!
      </div>

      <div className="self-center mt-8 lg:mt-12 mb-32 lg:mb-40">
        <a href="https://app.flexeet.club">
          <Button
            title="Start Creating Now"
            buttonStyle="btnPrimary"
            buttonSize="btnBig"
            isDisabled={false}
          />
        </a>
      </div>
    </div>
  )
}

export default ClosingSection
